import _vint from "./vint";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var vint = _vint;

function BufferReader(buffer) {
  (this || _global).buffer = buffer;
  (this || _global).offset = 0;
} // a super limited subset of the node buffer API


BufferReader.prototype.nextInt16BE = function () {
  var value = (this || _global).buffer.readInt16BE((this || _global).offset);

  (this || _global).offset += 2;
  return value;
};

BufferReader.prototype.nextUInt8 = function () {
  var value = (this || _global).buffer.readUInt8((this || _global).offset);

  (this || _global).offset += 1;
  return value;
}; // EBML variable sized integers


BufferReader.prototype.nextUIntV = function () {
  var v = vint((this || _global).buffer, (this || _global).offset);
  (this || _global).offset += v.length;
  return v.value;
};

BufferReader.prototype.nextIntV = function () {
  var v = vint((this || _global).buffer, (this || _global).offset, true);
  (this || _global).offset += v.length;
  return v.value;
}; // buffer slice


BufferReader.prototype.nextBuffer = function (length) {
  var buffer = length ? (this || _global).buffer.slice((this || _global).offset, (this || _global).offset + length) : (this || _global).buffer.slice((this || _global).offset);
  (this || _global).offset += length || (this || _global).length;
  return buffer;
}; // remaining bytes to read


Object.defineProperty(BufferReader.prototype, "length", {
  get: function () {
    return (this || _global).buffer.length - (this || _global).offset;
  }
});
exports = BufferReader;
export default exports;